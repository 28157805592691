<c-card class="mb-4">
  <c-card-header ngPreserveWhitespaces>
    <small>Profile</small>
  </c-card-header>
  <c-card-body>
    <div class="col">
      <strong>Name </strong>
    </div>
    <div class="col">
      {{profile?.givenName}} {{profile?.surname}}
    </div>
    <div class="col">
      <strong>User Principal Name </strong>
    </div>
    <div class="col">
      {{profile?.userPrincipalName}}
    </div>
    <div class="col">
      <strong>Object Id </strong>
    </div>
    <div class="col">
      {{profile?.id}}
    </div>
  </c-card-body>
</c-card>
