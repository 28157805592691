<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <c-header-nav class="d-none d-lg-flex me-auto">
      <c-nav-item>
        <a cNavLink routerLink="/transactions" routerLinkActive="active">
          Remote Sign - Admin Portal
        </a>
      </c-nav-item>


    </c-header-nav>


    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

  </c-container>
  <!-- <c-header-divider></c-header-divider> -->
  <!-- <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container> -->
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <c-avatar cTextColor="primary" shape="rounded-1" size="md" src="./assets/img/avatars/me.png" status="success">
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
      </li>



      <li></li>
      <li>
        <a routerLink="./profile" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>


      </li>
      <li>
        <hr cDropdownDivider />
      </li>
      <li>
        <a routerLink="./logout" cDropdownItem>
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Logout
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>
