import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Institutions',
    url: '/institutions',
    iconComponent: { name: 'cil-bank' },
  },
  {
    name: 'Group Roles',
    url: '/grouproles',
    iconComponent: { name: 'cil-people' },
  },
  {
    name: 'Connectors',
    url: '/connectors',
    iconComponent: { name: 'cil-input-power' },
  }
];
