import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './containers';
import { ProfileComponent } from './profile/profile.component';
import { LogoutComponent } from './logout/logout.component';
import { MsalGuard } from '@azure/msal-angular';
const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    redirectTo: 'institutions',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: '',
    },
    canActivate: [MsalGuard],
    children: [
      {
        path: 'institutions',
        loadChildren: () =>
          import('./views/tenants/tenants.module').then((m) => m.TenantsModule),
      },
      {
        path: 'grouproles',
        loadChildren: () =>
          import('./views/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'connectors',
        loadChildren: () =>
          import('./views/connectors/connectors.module').then(
            (m) => m.ConnectorsModule
          ),
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
    ],
  },

  { path: '**', redirectTo: 'institutions' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
